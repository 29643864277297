export const authMixin = {
  methods: {
    auth_onAuth(access_token, loggedInData, _referer) {
      let referer = _referer ? _referer : ''
      //call action onAuth in store
      let dataToDispatch = {
        access_token: access_token,
        loggedInData: loggedInData
      }
      this.$store.dispatch('onAuth', dataToDispatch).then(() => {
        console.log('auth_onAuth', referer)
        switch (referer) {
          default:
            this.$router.replace({ name: 'home'} )
        }
      })
    }
  }
}
