<template>
  <v-container>
    <v-card
      max-width="360"
      class="mx-auto my-12 px-8 py-4"
    >
      <v-card-text>
        <v-text-field
          v-model="username"
          label="Användarnamn"
          required
          outlined
          prepend-inner-icon="mdi-account-circle-outline"
        ></v-text-field>
        <v-text-field
          v-model="password"
          type="password"
          label="Lösenord"
          required
          outlined
          prepend-inner-icon="mdi-lock-outline"
          @keydown.enter="login()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <div v-show="nextLoginAttemptSeconds !== 0">Försök igen om {{ nextLoginAttemptSeconds }} s</div>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="login"
          :disabled="loginButtonDisabled"
        >
          Logga in
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
  </v-container>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import { appBus } from '../main'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { authMixin } from '../mixins/auth'

export default {

  name: 'Login',
  mixins: [generalMixin, authMixin, urlMixin],
  components: {
    'app-snack-bar': SnackBar,
  },
  data() {
    return {
      username: '',
      password: '',
      loginButtonDisabled: false,
      nextLoginAttemptSeconds: 0,
    }
  },
  watch: {
    nextLoginAttemptSeconds(value) {
      this.loginButtonDisabled = value !== 0;
    }
  },
  methods: {
    login() {
      const username = this.username.trim();
      const password = this.password.trim();
      if (username.length === 0 || password.length === 0) {
        return;
      }
      //get view data
      let config = {
        headers: {
        }
      }
      //post data to API
      let postData = {
        username,
        password
      }
      let url = this.url_composeApiUrl('/core/public/login-request')
  //    console.log("post url", url)
      console.log("postData", postData)
      axios.post(url, postData, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success
            if (res.data.validAccess) {
              this.auth_onAuth(res.data.token, res.data.loggedInData, 'login');
            } else {
              appBus.$emit('message', res.data.message);
              if (res.data.seconds) {
                //start timer for next login attempt
                this.nextLoginAttemptSeconds = res.data.seconds;
                this.initNextLoginAttemptCounter();
              }
            }
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
        })
        .catch(error => {
          this.displayAxiosCatchError(error)
        })
    },
    initNextLoginAttemptCounter() {
      console.log('initNextLoginAttemptCounter', this.nextLoginAttemptSeconds);
      setTimeout(() => {
        this.nextLoginAttemptSeconds--;
        if (this.nextLoginAttemptSeconds > 0) {
          this.initNextLoginAttemptCounter();
        }
      }, 1000);
    }
  }
}
</script>

<style>

</style>